<template>
  <div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column
            label="角色名称"
            prop="name"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="创建者"
            align="center"
            header-align="center"
            prop="createUserName"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="状态"
            align="center"
            header-align="center"
            width="80">
          <template slot-scope="scope">
            {{ scope.row.state === 1 ? '启用' : scope.row.state === 0 ? '禁用' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            align="center"
            header-align="center"
            prop="createTime"
            :formatter="commonFormatter"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
export default {
  name: "RoleList",
  mixins:[tableUse],
  data() {
    return {
      tableData: [
      ],
    }
  },
  methods:{
    getTableData(){
      this.tableLoading = true;
      let formData = {
        pageNum:this.pageNum,
        pageSize:this.pageSize
      }
      this.$axios.post("/sys-api/role-api/searchRolePage",formData,(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      })
    }
  },
  mounted() {
    this.getTableData();
  }
}
</script>

<style scoped lang="scss">
  .table-container{
    height: calc(100% - 50px - 20px);
    padding-top: 20px;
  }
</style>
